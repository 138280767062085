import React from "react";
import {
  FaFacebook,
  FaGithub,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
  FaVoicemail,
  FaXRay,
} from "react-icons/fa";
import { FaX } from "react-icons/fa6";
import { GiMailbox } from "react-icons/gi";
import { TfiEmail } from "react-icons/tfi";

function Footer() {
  return (
    <section id="footer">
      <div className="footer max-w-[1240px] mx-auto px-4 py-16 grid lg:grid-cols-3 gap-8 text-gray-300">
        <div className="footer-content lg:col-span-1">
          <h1 className="text-3xl font-bold text-[#00df9a]">One Plus One</h1>
          <p className="py-4">
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sapiente,
            cum pariatur. Atque voluptatibus velit magni cupiditate architecto
            cumque illum dolore ut? Recusandae inventore, quam explicabo
            nesciunt nemo illo voluptatibus itaque.
          </p>
          <div className="flex space-x-4 my-6">
            <FaFacebook
              size={30}
              className="cursor-pointer hover:text-[#00df9a]"
              onClick={() => (window.location.href = "javascript:void(0)")}
            />
            <FaTwitter
              size={30}
              className="cursor-pointer hover:text-[#00df9a]"
              onClick={() => (window.location.href = "javascript:void(0)")}
            />
            <FaLinkedin
              size={30}
              className="cursor-pointer hover:text-[#00df9a]"
              onClick={() =>
                (window.location.href =
                  "https://ke.linkedin.com/company/mcb-technologies-ltd")
              }
            />
            <FaInstagram
              size={30}
              className="cursor-pointer hover:text-[#00df9a]"
              onClick={() => (window.location.href = "javascript:void(0)")}
            />
            <FaGithub
              size={30}
              className="cursor-pointer hover:text-[#00df9a]"
              onClick={() =>
                (window.location.href = "https://github.com/Demabio")
              }
            />
            <TfiEmail
              size={30}
              className="cursor-pointer hover:text-[#00df9a]"
              onClick={() => (window.location.href = "mcbcaredesk@gmail.com")}
            />
          </div>
        </div>
        <div className="lg:col-span-2 flex justify-between">
          <div>
            <h6 className="font-bold text-lg mb-4">Solutions</h6>
            <ul>
              <li
                className="pricing py-2 text-sm hover:text-[#00df9a] cursor-pointer"
                onClick={() => (window.location.href = "#pricing")}
              >
                Pricing
              </li>
              <li className="py-2 text-sm hover:text-[#00df9a] cursor-pointer">
                Marketing
              </li>
              <li className="py-2 text-sm hover:text-[#00df9a] cursor-pointer">
                Guides
              </li>
              <li className="py-2 text-sm hover:text-[#00df9a] cursor-pointer">
                API Status
              </li>
            </ul>
          </div>
          <div>
            <h6 className="font-bold text-lg mb-4">Analytics</h6>
            <ul>
              <li className="py-2 text-sm hover:text-[#00df9a] cursor-pointer">
                Analytics
              </li>
              <li className="py-2 text-sm hover:text-[#00df9a] cursor-pointer">
                Marketing
              </li>
              <li className="py-2 text-sm hover:text-[#00df9a] cursor-pointer">
                Commerce
              </li>
              <li className="py-2 text-sm hover:text-[#00df9a] cursor-pointer">
                Insights
              </li>
            </ul>
          </div>
          <div>
            <h6 className="font-bold text-lg mb-4">Company</h6>
            <ul>
              <li
                className="py-2 text-sm hover:text-[#00df9a] cursor-pointer"
                onClick={() => (window.location.href = "https://mcb.co.ke/")}
              >
                About
              </li>
              <li
                className="py-2 text-sm hover:text-[#00df9a] cursor-pointer"
                onClick={() => (window.location.href = "https://mcb.co.ke/")}
              >
                Company
              </li>
              <li
                className="py-2 text-sm hover:text-[#00df9a] cursor-pointer"
                onClick={() =>
                  (window.location.href = "https://mcb.co.ke/blog-grids.html")
                }
              >
                Blog
              </li>
              <li
                className="py-2 text-sm hover:text-[#00df9a] cursor-pointer"
                onClick={() =>
                  (window.location.href = "https://mcb.co.ke/careers.html")
                }
              >
                Careers
              </li>
            </ul>
          </div>
          <div>
            <h6 className="font-bold text-lg mb-4">Legal</h6>
            <ul>
              <li className="py-2 text-sm hover:text-[#00df9a] cursor-pointer">
                Policy
              </li>
              <li className="py-2 text-sm hover:text-[#00df9a] cursor-pointer">
                Property
              </li>
              <li className="py-2 text-sm hover:text-[#00df9a] cursor-pointer">
                Terms
              </li>
              <li
                className="py-2 text-sm hover:text-[#00df9a] cursor-pointer"
                onClick={() => (window.location.href = "")}
              >
                Regulations
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Footer;