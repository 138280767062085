import Analytics from "./components/Analytics";
import CopyRight from "./components/CopyRight";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import NavBar from "./components/NavBar";
import NewsLetter from "./components/NewsLetter";
import Plans from "./components/plans";

function App() {
  return (
    <div className="w-full h-auto">
      <NavBar />
      <Hero />
      <Analytics />
      <NewsLetter />
      <Plans />
      <Footer />
      <CopyRight />
    </div>
  );
}

export default App;