import React from 'react'

function Button() {
  return (
    <div>
      <button
        className={
          onclick
            ? "bg-yellow-500 w-[200px] rounded-md font-medium my-6 mx-auto py-3"
            : "bg-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto py-3"
        }
        onClick={() =>
          (window.location.href =
            "https://baxiscore.com/oneplusone/account/login")
        }
      >
        Sign In
      </button>
    </div>
  );
}

export default Button;