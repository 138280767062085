import React from "react";

function NewsLetter() {
  return (
    <section id="newsletter">
      <div className="bg-black text-white w-full py-16 px-4">
        <div className="max-w-[1240px] mx-auto grid lg:grid-cols-3 gap-8">
          <div className="lg:col-span-2">
            <h1 className="md:text-5xl sm:text-4xl text-2xl font-bold py-2">
              Want Tips and Tricks for the Future of Finance?
            </h1>
            <p className="py-2">Sign up to our newsletter and stay up to date.</p>
          </div>
          <div className="flex flex-col md:flex-row items-center justify-between w-full lg:col-span-1">
            <input
              className="p-3 flex rounded-md w-full md:w-auto"
              type="email"
              placeholder="Enter Your Email Address"
            />
            <button className="bg-[#00df9a] w-[200px] rounded-md font-medium my-6 md:my-0 md:ml-4 py-3 text-black">
              Notify Me
            </button>
          </div>
          <p className="lg:col-span-3 text-center">
            We care about the privacy of your data.{" "}
            <span className="text-[#00df9a] cursor-pointer">
              Read our Policy.
            </span>
          </p>
        </div>
      </div>
    </section>

  );
}

export default NewsLetter;