import React from "react";
import Basic from '../assets/BAsic1.png'
import Standard from '../assets/Basic.jpeg'
import premium from '../assets/premium.webp'

function Plans() {
  return (
    <section id="pricing">
      <div className="w-full bg-white py-[10rem] px-4">
        <div className="mx-auto grid max-w-[1240px] md:grid-cols-3 sm:grid-cols-1 gap-8">
          <div className="w-full shadow-xl bg-gray-100 flex flex-col p-4 md:my-0 my-8 rounded-lg hover:scale-105 duration-300">
            <img className="mx-auto mt-[-3rem]" src={Basic} alt="/" />
            <h2 className="text-2xl font-bold text-center py-8">Basic</h2>
            <p className="text-center text-4xl font-bold">$49</p>
            <div className="text-center font-medium">
              <p className="py-2 border-b mx-8 mt-8">1 TB Storage</p>
              <p className="py-2 border-b mx-8">10 Users</p>
              <p className="py-2 border-b mx-8">Send up to 10 GB</p>
            </div>
            <button className="bg-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto py-3">
              Start Trial
            </button>
          </div>
          <div className="w-full shadow-xl bg-gray-100 flex flex-col p-4 md:my-0 my-8 rounded-lg hover:scale-105 duration-300">
            <img className="mx-auto mt-[-3rem]" src={Standard} alt="/" />
            <h2 className="text-2xl font-bold text-center py-8">Standard</h2>
            <p className="text-center text-4xl font-bold">$49</p>
            <div className="text-center font-medium">
              <p className="py-2 border-b mx-8 mt-8">1 TB Storage</p>
              <p className="py-2 border-b mx-8">10 Users</p>
              <p className="py-2 border-b mx-8">Send up to 10 GB</p>
            </div>
            <button className="bg-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto py-3">
              Try Standard
            </button>
          </div>
          <div className="w-full shadow-xl bg-gray-100 flex flex-col p-4 md:my-0 my-8 rounded-lg hover:scale-105 duration-300">
            <img className="mx-auto mt-[-3rem]" src={premium} alt="/" />
            <h2 className="text-2xl font-bold text-center py-8">Premium</h2>
            <p className="text-center text-4xl font-bold">$49</p>
            <div className="text-center font-medium">
              <p className="py-2 border-b mx-8 mt-8">1 TB Storage</p>
              <p className="py-2 border-b mx-8">10 Users</p>
              <p className="py-2 border-b mx-8">Send up to 10 GB</p>
            </div>
            <button className="bg-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto py-3">
              Try Premium
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Plans;
