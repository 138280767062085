import React from "react";
import laptop from "../assets/logo_1.jpeg";
import { BiArrowToRight } from "react-icons/bi";

function Analytics() {
  return (
    <div className="bg-white w-full py-16 px-4">
      <div className="max-w-[1240px] mx-auto grid md:grid-cols-2">
        <img src={laptop} alt="/" className="w-[500px] mx-auto my-4" />
        <div className="flex flex-col justify-center">
          <p className="text-[#00df9a] font-bold uppercase">
            Loans Management Dashboard
          </p>
          <h1 className="md:text-5xl sm:text-4xl text-xl py-2 font-bold">
            Manage Loans Centrally
          </h1>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum ea
            perspiciatis nostrum eius vel vitae atque doloribus quos
            necessitatibus ratione, fuga, ad deserunt autem harum animi dolorem,
            esse iure sed? 300
          </p>
          <button
            className="bg-[#141b19] w-[200px] rounded-md font-medium my-6 mx-auto py-3 text-[#00df9a] "
            onClick={() =>
              (window.location.href =
                "https://baxiscore.com/oneplusone/account/login")
            }
          >
            Sign In
            {/* <BiArrowToRight className="ml-10 size-9 items-center text-center" /> */}
          </button>
        </div>
      </div>
    </div>
  );
}
export default Analytics;
